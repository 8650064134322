/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import $ from "jquery"

export const onRouteUpdate = ({ location }) => {
  const sectionToScroll = location.hash.split("#")[1]
  if (sectionToScroll) {
    $("html, body").animate(
      {
        scrollTop: $(`.${sectionToScroll}`).offset().top,
      },
      1000
    )
  }
}
